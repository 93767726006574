// src/components/OurPrograms.js
import React from 'react';
import { Box, Container, Typography, Grid, Link } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import AmazePuzzlesImage from '../assets/assorted-puzzle-pieces.jpg';
import '../App.css';

const OurPrograms = () => {
  const theme = useTheme();

  return (
    <Box className="our-programs-section" sx={{ py: 5 }}>
      <Container>
        <Typography 
          variant="h2" 
          gutterBottom 
          sx={{ textAlign: 'center', fontSize: '2.5rem', color: theme.palette.primary.main }}
        >
          The Work We're Doing
        </Typography>
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              component="img"
              src={AmazePuzzlesImage}
              alt="Amaze Puzzles"
              sx={{
                width: '100%',
                borderRadius: 2,
                boxShadow: 3,
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography 
              variant="h5" 
              component="div" 
              sx={{ fontSize: '2rem', color: theme.palette.primary.main, mb: 2 }}
            >
              Amaze Puzzles
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ fontSize: '1.2rem', color: '#4c4c4c;', mb: 2 }}
            >
              Amaze Puzzles is an innovative product designed to help teach Braille literacy in a fun and engaging way. 
              It empowers learners of all ages to develop and retain their Braille skills through interactive and 
              challenging puzzles that are both educational and entertaining.
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ fontSize: '1.2rem', color: '#4c4c4c;', mb: 2 }}
            >
              Our puzzles are designed with inclusivity in mind, ensuring that everyone has the opportunity to learn and grow, 
              regardless of their visual abilities. Amaze Puzzles is the first of many tools Dual Tools plans to develop, 
              focusing on making education accessible to all.
            </Typography>
            <Typography 
              variant="body1" 
              sx={{ fontSize: '1.2rem', color: '#4c4c4c;' }}
            >
              Learn more about Amaze Puzzles on our official website:{" "}
              <Link href="https://www.amazepuzzles.com" target="_blank" rel="noopener" sx={{ color: '#033D74', fontWeight: 'bold' }}>
                amazepuzzles.com
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default OurPrograms;
