// src/App.js
import React, { useRef, useState } from 'react';
import Hero from './components/Hero';
import About from './components/About';
import OurPrograms from './components/OurPrograms';
import GetInvolved from './components/GetInvolved';
import Footer from './components/Footer';
import ContactModal from './components/Contact/ContactModal';
import { Box, Typography, Button } from '@mui/material';
import './App.css';

const App = () => {
  const aboutRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const scrollToNextSection = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSuccess = () => {
    setShowSuccessMessage(true);
    setTimeout(() => {
      setShowSuccessMessage(false);
    }, 5000); // Hide after 5 seconds, or user can close it manually
  };

  const closeSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  return (
    <div>
      <div name="hero">
        <Hero scrollToNextSection={scrollToNextSection} />
      </div>
      <div className="section-spacer" />
      <div name="about-us" ref={aboutRef}>
        <About />
      </div>
      <div className="section-spacer" />
      <div name="our-programs">
        <OurPrograms />
      </div>
      <div className="section-spacer" />
      <div name="get-involved">
        <GetInvolved onContactClick={handleOpenModal} />
      </div>
      <Footer />
      <ContactModal open={isModalOpen} onClose={handleCloseModal} onSuccess={handleSuccess} />

      {showSuccessMessage && (
        <Box
          sx={{
            position: 'fixed',
            top: 0,
            left: '50%',
            transform: 'translateX(-50%)',
            backgroundColor: 'green',
            color: 'white',
            p: 2,
            borderRadius: 1,
            zIndex: 9999,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: '600px',
          }}
        >
          <Typography>
            Message sent successfully
          </Typography>
          <Button onClick={closeSuccessMessage} sx={{ color: 'white', marginLeft: 2 }}>
            Close
          </Button>
        </Box>
      )}
    </div>
  );
};

export default App;
