// src/components/Contact/ContactModal.js
import React from 'react';
import { Modal, Box } from '@mui/material';
import ContactForm from './ContactForm';

const ContactModal = ({ open, onClose, onSuccess }) => {
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
        <ContactForm onClose={onClose} onSuccess={onSuccess} />
      </Box>
    </Modal>
  );
};

export default ContactModal;
