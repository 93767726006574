import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import axios from 'axios';

const ContactForm = ({ onClose, onSuccess }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const API_URL = 'https://nnlm13a975.execute-api.us-west-2.amazonaws.com/prod/contact';

  useEffect(() => {
    // Check if all fields are filled
    if (name && email && message) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [name, email, message]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setErrorMessage(''); // Clear any existing error message

    try {
      const response = await axios.post(API_URL, {
        name,
        email,
        message,
      });

      if (response.status === 200) {
        console.log('Response:', response.data);

        // Call the onSuccess function to show the success notification
        onSuccess();

        // Close the modal after a short delay
        setTimeout(onClose, 1000);
      } else {
        setErrorMessage('Failed to send your message. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Failed to send your message. Please try again.');
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{ p: 4, backgroundColor: 'white', borderRadius: 2, maxWidth: 400, mx: 'auto' }}
    >
      <Typography variant="h6" gutterBottom>
        Get in Touch
      </Typography>
      <TextField
        fullWidth
        margin="normal"
        label="Your Name"
        variant="outlined"
        InputLabelProps={{
          style: { color: '#333' },
        }}
        required
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Your Email"
        variant="outlined"
        InputLabelProps={{
          style: { color: '#333' },
        }}
        required
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <TextField
        fullWidth
        margin="normal"
        label="Your Message"
        variant="outlined"
        multiline
        rows={4}
        InputLabelProps={{
          style: { color: '#333' },
        }}
        required
        value={message}
        onChange={(e) => setMessage(e.target.value)}
      />
      <Box sx={{ mt: 2, textAlign: 'right' }}>
        <Button onClick={onClose} sx={{ mr: 2 }}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          type="submit"
          disabled={isSubmitDisabled || isSubmitting}
        >
          {isSubmitting ? 'Submitting...' : 'Submit'}
        </Button>
      </Box>
      {errorMessage && (
        <Typography variant="body2" color="error" sx={{ mt: 2 }}>
          {errorMessage}
        </Typography>
      )}
    </Box>
  );
};

export default ContactForm;
