// src/components/AboutUs.js
import React from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Avatar from '@mui/material/Avatar';
import { useTheme } from '@mui/material/styles';
import joshSimpson from '../assets/josh-simpson.jpg';
import christopherSimpson from '../assets/christopher-animated.jpg';
import dorianSimpson from '../assets/dorian-simpson.jpg';

const AboutUs = () => {
  const theme = useTheme();

  return (
    <Box 
      className="about-us-section"
      sx={{ 
        position: 'relative', 
        backgroundColor: '#f4f6f8', 
        minHeight: '100vh', 
        py: 8, 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'center' 
      }}
    >
      <Container sx={{ position: 'relative', zIndex: 1 }}>
        <Typography variant="h2" gutterBottom sx={{ textAlign: 'center', fontSize: '2.5rem', color: theme.palette.primary.main }}>
          Meet DUAL Tools
        </Typography>
        <Typography variant="body1" paragraph sx={{ textAlign: 'center', mb: 4, fontSize: '1.2rem', color: theme.palette.text.primary }}>
          Dual Tools is a non-profit organization dedicated to enhancing accessibility and learning for all. Our mission is to create innovative tools that promote diversity and inclusivity in education and beyond.
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 3, height: '100%', backgroundColor: '#e3f2fd' }}>
              <Typography variant="h5" gutterBottom sx={{ fontSize: '1.5rem', color: theme.palette.primary.main }}>
                Our Vision
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem', color: theme.palette.text.primary }}>
                To create a world where educational opportunities are accessible to all, regardless of their abilities. We believe in a future where diversity in learning is celebrated and supported.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 3, height: '100%', backgroundColor: '#fce4ec' }}>
              <Typography variant="h5" gutterBottom sx={{ fontSize: '1.5rem', color: theme.palette.primary.main }}>
                Our Mission
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem', color: theme.palette.text.primary }}>
                Dual Tools initiative is dedicated to placing innovative educational tools into the hands of all learners, including those with diverse needs, thereby empowering them to explore, learn, and grow.
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ p: 3, height: '100%', backgroundColor: '#e8f5e9' }}>
              <Typography variant="h5" gutterBottom sx={{ fontSize: '1.5rem', color: theme.palette.primary.main }}>
                Our Values
              </Typography>
              <Typography variant="body1" sx={{ fontSize: '1rem', color: theme.palette.text.primary }}>
                We value inclusivity, innovation, and collaboration. We strive to create tools and resources that are not only effective but also accessible and engaging for all learners.
              </Typography>
            </Paper>
          </Grid>
        </Grid>
        <Box sx={{ mt: 6, display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ maxWidth: '1000px', textAlign: 'left' }}>
            <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', fontSize: '2rem', color: theme.palette.primary.main }}>
              Our Goals
            </Typography>
            <Typography variant="body1" paragraph sx={{ fontSize: '1.1rem', color: theme.palette.text.primary }}>
              As a newly formed non-profit, Dual Tools aims to establish a strong foundation and expand our reach. Our goals include:
            </Typography>
            <Box 
              component="ul" 
              sx={{ 
                pl: 3, 
                color: theme.palette.text.primary, 
                fontSize: '1.1rem', 
                listStyleType: 'disc', 
                textAlign: 'left', 
                fontFamily: theme.typography.fontFamily // Add this line
              }}
            >
              <Box component="li" sx={{ mb: 2 }}>
                Developing a comprehensive suite of educational tools that cater to diverse learning needs.
              </Box>
              <Box component="li" sx={{ mb: 2 }}>
                Building partnerships with schools, non-profit organizations, and community groups to implement our tools and resources effectively.
              </Box>
              <Box component="li" sx={{ mb: 2 }}>
                Conducting research and gathering feedback to continuously improve our tools and ensure they meet the needs of all learners.
              </Box>
              <Box component="li" sx={{ mb: 2 }}>
                Raising awareness about the importance of accessibility and inclusivity in education.
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ mt: 8 }}>
          <Typography variant="h5" gutterBottom sx={{ textAlign: 'center', fontSize: '2rem', color: theme.palette.primary.main }}>
            Our Team
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            <Grid item xs={12} sm={4} md={3}>
              <Paper 
                elevation={3} 
                sx={{ 
                  p: 3, 
                  textAlign: 'center', 
                  transition: 'transform 0.3s', 
                  '&:hover': {
                    transform: 'scale(1.05)',
                  } 
                }}
              >
                <Avatar alt="Joshua H" src={joshSimpson} sx={{ width: 150, height: 150, margin: '0 auto 16px' }} />
                <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.main }}>
                  Joshua H
                </Typography>
                <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
                  Executive Director
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Paper 
                elevation={3} 
                sx={{ 
                  p: 3, 
                  textAlign: 'center', 
                  transition: 'transform 0.3s', 
                  '&:hover': {
                    transform: 'scale(1.05)',
                  } 
                }}
              >
                <Avatar alt="Christopher B" src={christopherSimpson} sx={{ width: 150, height: 150, margin: '0 auto 16px' }} />
                <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.main }}>
                  Christopher B
                </Typography>
                <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
                  Director of Wellness
                </Typography>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={4} md={3}>
              <Paper 
                elevation={3} 
                sx={{ 
                  p: 3, 
                  textAlign: 'center', 
                  transition: 'transform 0.3s', 
                  '&:hover': {
                    transform: 'scale(1.05)',
                  } 
                }}
              >
                <Avatar alt="Dorian S" src={dorianSimpson} sx={{ width: 150, height: 150, margin: '0 auto 16px' }} />
                <Typography variant="h6" gutterBottom sx={{ color: theme.palette.primary.main }}>
                  Dorian S
                </Typography>
                <Typography variant="body1" sx={{ color: theme.palette.text.primary }}>
                  Director Of Technology
                </Typography>
              </Paper>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutUs;
