// src/components/GetInvolved.js
import React from 'react';
import { Box, Container, Typography, Button } from '@mui/material';

const GetInvolved = ({ onContactClick }) => {
  return (
    <Box className='get-involved-section' sx={{ py: 5}}>
      <Container>
        <Typography variant="h2" gutterBottom>
          Get Involved
        </Typography>
        <Typography variant="body1" paragraph sx={{color: 'black'}}>
          There are many ways to get involved with Dual Tools. Whether you want to volunteer, donate, or partner with us, your support helps us make a difference in the lives of many.
        </Typography>
        <Button variant="contained" color="primary" size="large" onClick={onContactClick}>
          Contact Us
        </Button>
      </Container>
    </Box>
  );
};

export default GetInvolved;
