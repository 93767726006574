// src/components/Hero.js
import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, Typography } from '@mui/material';
import heroImage from '../assets/hero.jpg';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import '../App.css';

const Hero = ({ scrollToNextSection }) => {
  const [showScrollIndicator, setShowScrollIndicator] = useState(true);
  const heroRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting) {
          setShowScrollIndicator(false);
        } else {
          setShowScrollIndicator(true);
        }
      },
      { threshold: 0.4 }
    );

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }
    };
  }, []);

  return (
    <Box 
      className="hero-container" 
      sx={{ 
        backgroundImage: `url(${heroImage})`,
      }}
      ref={heroRef}
    >
      <Box className="hero-overlay" />
      <Container className="hero-content">
        <Typography 
          variant="h2" 
          component="h1" 
          gutterBottom 
          className="hero-title"
        >
          DUAL Tools
        </Typography>
        <Typography 
          variant="h5" 
          className="hero-subtitle"
        >
          Diverse Universal Accessibility and Learning Tools.
        </Typography>
        <Typography 
          variant="body1" 
          className="hero-text"
        >
          Dual Tools initiative is dedicated to placing innovative educational Tools into the hands of all learners including those with diverse needs thereby empowering them to explore, learn, and grow.
        </Typography>
      </Container>
      <Box 
        className={`scroll-indicator ${!showScrollIndicator ? 'hidden' : ''}`}
        onClick={scrollToNextSection}
      >
        <KeyboardArrowDownIcon 
          sx={{ 
            fontSize: '3rem', 
            animation: showScrollIndicator ? 'bounce 2s infinite' : 'none', 
            cursor: 'pointer' 
          }}
        />
      </Box>
    </Box>
  );
};

export default Hero;
