import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import IconButton from '@mui/material/IconButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import EmailIcon from '@mui/icons-material/Email';
import { useTheme } from '@mui/material/styles';

const Footer = () => {
  const theme = useTheme();

  return (
    <Box component="footer" sx={{ py: 4, px: 2, backgroundColor: theme.palette.primary.main, color: 'white' }}>
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom sx={{ color: '#ffffff' }}>
            About Us
          </Typography>
          <Typography variant="body2" sx={{ color: '#ffffff' }}>
            Dual Tools is dedicated to enhancing accessibility and learning for all. We create innovative tools that promote diversity and inclusivity in education.
          </Typography>
        </Grid>

        {/* <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom sx={{ color: '#ffffff' }}>
            Contact Us
          </Typography>
          <Typography variant="body2" sx={{ color: '#ffffff' }}>
            Email: <Link href="mailto:info@dualtools.com" sx={{ color: '#ffffff' }}>info@dualtools.com</Link>
          </Typography>
        </Grid> */}

        <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}>
          <Typography variant="h6" gutterBottom sx={{ color: '#ffffff' }}>
            Follow Us
          </Typography>
          <IconButton href="https://facebook.com" target="_blank" sx={{ color: '#ffffff' }}>
            <FacebookIcon />
          </IconButton>
          <IconButton href="https://twitter.com" target="_blank" sx={{ color: '#ffffff' }}>
            <TwitterIcon />
          </IconButton>
          <IconButton href="https://linkedin.com" target="_blank" sx={{ color: '#ffffff' }}>
            <LinkedInIcon />
          </IconButton>
          <IconButton href="mailto:info@dualtools.com" sx={{ color: '#ffffff' }}>
            <EmailIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Box sx={{ mt: 4, textAlign: 'center', borderTop: '1px solid rgba(255, 255, 255, 0.3)', pt: 2 }}>
        <Typography variant="body2" sx={{ color: '#ffffff' }}>
          &copy; 2024 Dual Tools. All rights reserved.
        </Typography>
        {/* <Typography variant="body2" sx={{ color: '#ffffff' }}>
          <Link href="/privacy-policy" sx={{ color: '#ffffff', textDecoration: 'none' }}>Privacy Policy</Link> | <Link href="/terms-of-service" sx={{ color: '#ffffff', textDecoration: 'none' }}>Terms of Service</Link>
        </Typography> */}
      </Box>
    </Box>
  );
};

export default Footer;
