// src/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#4caf50', // Green
    },
    secondary: {
      main: '#8bc34a', // Light Green
    },
    background: {
      default: '#ffffff',
      paper: '#f4f6f8',
    },
    text: {
      primary: '#2c3e50',
      secondary: '#bdc3c7',
    },
  },
  typography: {
    fontFamily: [
      'Montserrat',   // Replace Moderat with Montserrat
      'Roboto',
      'Open Sans',
      'Arial',
      'sans-serif'
    ].join(','),
    h1: {
      fontFamily: 'Montserrat',
      fontWeight: 700,
      fontSize: '2.5rem',
      color: '#4caf50',
    },
    h2: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: '2rem',
      color: '#4caf50',
    },
    h3: {
      fontFamily: 'Montserrat',
      fontWeight: 500,
      fontSize: '1.75rem',
      color: '#4caf50',
    },
    body1: {
      fontFamily: 'Montserrat',
      fontWeight: 400,
      fontSize: '1rem',
      color: '#fff',
    },
    body2: {
      fontFamily: 'Montserrat',
      fontWeight: 300,
      fontSize: '0.875rem',
      color: '#2c3e50',
    },
  },
});

export default theme;
